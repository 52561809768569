@charset "UTF-8";
@font-face {
  font-family: "font-awesome";
  src: url("https://fonts.au.dk/fonts/fa-pro-light-300.otf");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "font-awesome";
  src: url("https://fonts.au.dk/fonts/fa-pro-solid-900.otf");
  font-weight: 900;
  font-style: bold;
}
body.general.universe.staff {
  background-color: #379fcb;
}
body.general.universe.staff a {
  color: #003e5c;
}
body.general.universe.staff .row.sectionheader {
  background-color: #f0efef;
}
body.general.universe.staff .universefooter,
body.general.universe.staff .localfooter,
body.general.universe.staff .globalfooter,
body.general.universe.staff #au_footer {
  background-color: #f0efef;
}
body.general.universe.staff footer.global .columns {
  background-color: #f0efef;
  color: inherit;
}
body.general.universe.staff footer.global .columns .seal {
  filter: invert(1);
}
body.general.universe.staff footer > .page-id.row {
  color: #f0efef;
}
body.general.universe.staff footer > .page-id.row:hover {
  color: #003e5c;
}
body.general.universe.staff label,
body.general.universe.staff li:before {
  color: #379fcb;
}
body.general.universe.staff #au_content .column .au_collapsible .csc-header h2,
body.general.universe.staff #au_content .columns .au_collapsible .csc-header h2,
body.general.universe.staff .au_collapsible .csc-header h2 {
  color: #003e5c;
}
body.general.universe.staff.staffservice .row.sectionheader {
  background-color: #003e5c;
}
body.general.universe.staff.staffservice .row.sectionheader a {
  color: #fff;
}
body.general.universe.staff.staffservice .row.sectionheader .sectiontitle > h2 {
  color: #fff;
}
body.general.universe.staff.staffservice .universefooter,
body.general.universe.staff.staffservice .localfooter,
body.general.universe.staff.staffservice .globalfooter,
body.general.universe.staff.staffservice #au_footer {
  background-color: #003e5c;
}
body.general.universe.staff.staffservice footer .column,
body.general.universe.staff.staffservice footer .columns {
  color: #fff;
}
body.general.universe.staff.staffservice footer .column li::before,
body.general.universe.staff.staffservice footer .column a,
body.general.universe.staff.staffservice footer .columns li::before,
body.general.universe.staff.staffservice footer .columns a {
  color: #fff;
}
body.general.universe.staff.staffservice footer.global .columns {
  background-color: #003e5c;
}
body.general.universe.staff.staffservice footer.global .columns .seal {
  filter: none;
}
body.general.universe.staff.staffservice footer > .page-id.row {
  color: #003e5c;
}
body.general.universe.staff.staffservice footer > .page-id.row:hover {
  color: #fff;
}
body.general.universe.staff .universeheader ul.universe-icons > li.active button {
  color: #379fcb;
}
body.general.universe.staff .left-menu a:hover,
body.general.universe.staff .left-menu li.au_current:before,
body.general.universe.staff .left-menu li.au_current a,
body.general.universe.staff .left-menu li.au_current ul a {
  color: #003e5c !important;
}
body.general.universe.staff .au_collapsible .csc-header h1, #au_content .column body.general.universe.staff .au_collapsible .csc-header h1 {
  color: #379fcb;
}
body.general.universe.staff .au_collapsible .csc-header h2, #au_content .column body.general.universe.staff .au_collapsible .csc-header h2 {
  color: #379fcb;
}
body.general.universe.staff .csc-frame.au_collapsible > .csc-header:after,
body.general.universe.staff .csc-frame.au_collapsed > .csc-header:after {
  color: #379fcb;
}
body.general.universe.staff .au_news-list-container button {
  background-color: #379fcb;
}
body.general.universe.staff .header-menu .mobile-nav button::after {
  background-color: #003e5c;
  box-shadow: 0 7px 0 #003e5c, 0 14px 0 #003e5c;
}
body.general.universe.staff .header-menu.open + .columns a {
  color: #003e5c;
}
body.general.universe.staff #au_alphabox_1:not(.front) .quicklinks, body.general.universe.staff #au_alphabox_1:not(.front) h3 {
  display: none;
}
body.general.universe.staff.staffservice .header-menu .mobile-nav button::after, body.general.universe.student .header-menu .mobile-nav button::after {
  background-color: #fff;
  box-shadow: 0 7px 0 #fff, 0 14px 0 #fff;
}
body.general.universe.staff.staffservice .header-menu.open + .columns a, body.general.universe.student .header-menu.open + .columns a {
  color: #fff;
}
body.general.universe.student {
  background-color: #8bad3f;
}
body.general.universe.student a {
  color: #2a4a0f;
}
body.general.universe.student .row.sectionheader {
  background-color: #2a4a0f;
}
body.general.universe.student .row.sectionheader a {
  color: #fff;
}
body.general.universe.student .universefooter,
body.general.universe.student .localfooter,
body.general.universe.student .globalfooter,
body.general.universe.student #au_footer {
  background-color: #2a4a0f;
}
body.general.universe.student footer .column,
body.general.universe.student footer .columns {
  color: #fff;
}
body.general.universe.student footer .column li::before,
body.general.universe.student footer .column a,
body.general.universe.student footer .columns li::before,
body.general.universe.student footer .columns a {
  color: #fff;
}
body.general.universe.student footer.global .columns {
  background-color: #2a4a0f;
}
body.general.universe.student footer > .page-id.row {
  color: #2a4a0f;
}
body.general.universe.student footer > .page-id.row:hover {
  color: #fff;
}
body.general.universe.student label,
body.general.universe.student li:before {
  color: #8bad3f;
}
body.general.universe.student ul.useful-links a {
  color: #fff !important;
}
body.general.universe.student #au_content .column .au_collapsible .csc-header h2,
body.general.universe.student #au_content .columns .au_collapsible .csc-header h2,
body.general.universe.student .au_collapsible .csc-header h2 {
  color: #2a4a0f;
}
body.general.universe.student .universeheader ul.universe-icons > li.active button {
  color: #8bad3f;
}
body.general.universe.student .left-menu a:hover,
body.general.universe.student .left-menu li.au_current:before,
body.general.universe.student .left-menu li.au_current a,
body.general.universe.student .left-menu li.au_current ul a {
  color: #2a4a0f !important;
}
body.general.universe.student .au_collapsible .csc-header h1, #au_content .column body.general.universe.student .au_collapsible .csc-header h1 {
  color: #8bad3f;
}
body.general.universe.student .au_collapsible .csc-header h2, #au_content .column body.general.universe.student .au_collapsible .csc-header h2 {
  color: #8bad3f;
}
body.general.universe.student .csc-frame.au_collapsible > .csc-header:after,
body.general.universe.student .csc-frame.au_collapsed > .csc-header:after {
  color: #8bad3f;
}
body.general.universe.student .au_news-list-container button {
  background-color: #8bad3f;
}
body.general.universe.level0 header ~ .row:not(.breadcrumb):first-of-type {
  padding-top: 2.7272728rem;
}
body.general.universe .au_focus h1 a {
  color: #fff;
}
body.general.universe .au_searchbox {
  position: relative;
}
body.general.universe .au_searchbox.active .au_searchform::before {
  display: none;
}
body.general.universe .au_searchbox .au_searchform {
  margin-top: 0;
}
body.general.universe .au_searchbox .au_searchform::before {
  display: inline-block;
  content: "7";
  position: absolute;
  font-family: "au_icons";
  font-size: 1.5rem;
  top: 5px;
  left: 10px;
  color: #ccc;
}
body.general.universe .au_searchbox .au_searchform input[type=search] {
  background-color: #fff !important;
  border: 1px solid #ccc !important;
}
@media screen and (max-width: 39.9375em) {
  body.general.universe .au_searchbox input[type=search] {
    border-bottom: 1px solid #cacaca;
  }
}
body.general.universe .button,
body.general.universe .au_button a,
body.general.universe .stafflinks a,
body.general.universe .aubanner.aubanner-2 a,
body.general.universe .aubanner.aubanner-2 h2,
body.general.universe .aubanner.aubanner-2 h3,
body.general.universe .aubanner.aubanner-2 p,
body.general.universe .neutral-container div[class*=bg-dark],
body.general.universe .neutral-container div[class*=bg-dark] a,
body.general.universe .neutral-container div[class*=bg-dark] li:before,
body.general.universe span.au_news-single-language a,
body.general.universe span.au_news-single-print a {
  color: #fff !important;
}
body.general.universe .aubanner.aubanner-2.lightgray a,
body.general.universe .aubanner.aubanner-2.lightgray h2,
body.general.universe .aubanner.aubanner-2.lightgray h3,
body.general.universe .aubanner.aubanner-2.lightgray li:before,
body.general.universe .aubanner.aubanner-2.lightgray p,
body.general.universe .aubanner.aubanner-2.white a,
body.general.universe .aubanner.aubanner-2.white h2,
body.general.universe .aubanner.aubanner-2.white h3,
body.general.universe .aubanner.aubanner-2.white li:before,
body.general.universe .aubanner.aubanner-2.white p,
body.general.universe .bg-dark-gray.aubanner-2:hover:after,
body.general.universe .bg-lightgray,
body.general.universe .neutral-container div[class*=bg-dark] .bg-lightgray {
  color: #333 !important;
}
body.general.universe .universeheader {
  position: relative;
}
body.general.universe .universeheader .columns > div {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  align-items: center;
  margin: 1rem 0 0 0;
}
@media screen and (min-width: 40em) {
  body.general.universe .universeheader .columns > div .avatar {
    order: 100;
    margin-right: 0;
  }
  body.general.universe .universeheader .columns > div ul.universe-icons {
    flex-basis: auto;
  }
}
@media screen and (max-width: 39.9375em) {
  body.general.universe .universeheader .columns > div {
    align-items: flex-start;
  }
}
body.general.universe .universeheader .columns > div > a,
body.general.universe .universeheader .columns > div .au a,
body.general.universe .universeheader .columns > div .avatar > a,
body.general.universe .universeheader .columns > div .mitstudie > a,
body.general.universe .universeheader .columns > div button:not(.copy-to-clipboard) {
  text-transform: uppercase;
  color: #fff;
}
body.general.universe .universeheader .columns > div button,
body.general.universe .universeheader .columns > div .avatar > a {
  display: block;
  font-family: "AUPassataRegular";
  font-size: 0.9rem;
  text-align: center;
  line-height: 1;
  padding: 0.5rem 0;
  cursor: pointer;
}
@media screen and (max-width: 39.9375em) {
  body.general.universe .universeheader .columns > div .avatar {
    display: flex;
    justify-content: flex-end;
    margin: 0;
    flex-basis: calc(100% - 19.7rem);
  }
  body.general.universe .universeheader .columns > div .avatar .sub-nav > button,
body.general.universe .universeheader .columns > div .avatar > a {
    display: flex;
    align-items: center;
    text-transform: none;
    font-size: 1.3rem;
    margin: 0 0.6rem;
    text-align: right;
    padding: 0;
  }
  body.general.universe .universeheader .columns > div .avatar .sub-nav > button::before,
body.general.universe .universeheader .columns > div .avatar > a::before {
    order: 2;
    margin-left: 0.5rem;
  }
}
body.general.universe .universeheader .columns > div button::before {
  display: block;
  font-family: "au_icons";
  font-size: 3.6rem;
  text-transform: none;
  overflow: hidden;
  height: calc(3.6rem - 11px);
}
body.general.universe .universeheader .columns > div button.btn-system::before {
  content: "Z";
}
body.general.universe .universeheader .columns > div button.btn-find::before {
  content: "f";
}
body.general.universe .universeheader .columns > div button.btn-menu::before {
  content: "m";
}
body.general.universe .universeheader .universe-home {
  flex-grow: 1;
  font-size: 1.8181818182rem;
  text-transform: uppercase;
  font-family: "AUPassataRegular";
  margin-bottom: 0;
}
body.general.universe .universeheader .universe-home > a {
  color: #fff;
}
body.general.universe .universeheader .universe-home > a > strong {
  font-family: "AUPassataBold";
}
body.general.universe .universeheader .avatar {
  margin: 0 0.5rem;
  z-index: 102;
  position: relative;
}
body.general.universe .universeheader .avatar .btn-help {
  position: absolute;
  top: -3px;
  right: -10px;
  font-family: "font-awesome";
  font-weight: bold;
  font-size: 2rem;
  color: #fabb00 !important;
  padding: 0;
}
@media screen and (max-width: 39.9375em) {
  body.general.universe .universeheader .avatar .btn-help {
    top: -4px;
    right: -4px;
    font-size: 1.6rem;
  }
}
body.general.universe .universeheader .avatar > .sub-nav > button::before,
body.general.universe .universeheader .avatar > a::before {
  font-family: "font-awesome";
  font-weight: 300;
  display: block;
}
body.general.universe .universeheader .avatar > .sub-nav {
  position: relative;
}
body.general.universe .universeheader .avatar > .sub-nav > button::before {
  content: "";
  font-size: 2.54rem;
}
body.general.universe .universeheader .avatar > .sub-nav.sub-nav--toggled > button::before {
  font-weight: bold;
}
body.general.universe .universeheader .avatar > a:hover {
  text-decoration: none;
}
body.general.universe .universeheader .avatar > a::before {
  content: "";
  font-size: 2.6rem;
  text-transform: none;
}
body.general.universe .universeheader .avatar .sub-nav__content {
  position: absolute;
  background-color: #fff;
  top: 100%;
  right: 0;
  padding: 2rem;
  width: 270px;
  box-shadow: 0 0.2rem 0.6rem 0 rgba(0, 0, 0, 0.4);
  z-index: 101;
}
body.general.universe .universeheader .avatar .sub-nav__content button {
  color: #0a0a0a;
  font-family: "AUPassataRegular";
}
body.general.universe .universeheader .avatar .sub-nav__content .sub-nav__user .sub-nav__user-name {
  margin: 0 0 0.4rem 0;
}
body.general.universe .universeheader .avatar .sub-nav__content .sub-nav__user button {
  font-size: 1rem;
  color: #6e6e6e;
}
body.general.universe .universeheader .avatar .sub-nav__content .sub-nav__user button:hover {
  color: #65a1ba;
}
body.general.universe .universeheader .avatar .sub-nav__content .sub-nav__user button:hover::after {
  font-family: "font-awesome";
  font-weight: 300;
  margin-left: 0.5rem;
  content: "";
}
body.general.universe .universeheader .avatar .sub-nav__content .sub-nav__item {
  display: block;
  padding: 0.3125rem 0;
  margin-bottom: 0.3476rem;
  color: #000;
}
body.general.universe .universeheader .avatar .sub-nav__content .sub-nav__item:hover {
  color: #5e5e5e;
  text-decoration: none;
}
body.general.universe .universeheader .avatar .sub-nav__content .sub-nav__item--icon::before {
  content: attr(data-icon);
  font-family: "font-awesome";
  font-weight: 300;
  margin-right: 0.5rem;
}
body.general.universe .universeheader ul.universe-icons {
  display: flex;
  justify-content: flex-end;
  flex-basis: 100%;
  align-items: center;
  position: relative;
  z-index: 100;
  margin-bottom: 0;
}
@media screen and (max-width: 39.9375em) {
  body.general.universe .universeheader ul.universe-icons {
    margin-top: 0.5rem;
  }
}
body.general.universe .universeheader ul.universe-icons li.au {
  margin-right: 1rem;
}
body.general.universe .universeheader ul.universe-icons li.mitstudie {
  background-color: #667e2e;
  font-family: "AUPassataBold";
  margin-right: 1.4rem;
}
body.general.universe .universeheader ul.universe-icons li.mitstudie a {
  display: block;
  box-shadow: rgba(0, 0, 0, 0.4) 0px 3.2px 9.6px 0px;
  padding: 6.18px 15.82px;
}
body.general.universe .universeheader ul.universe-icons li.mitstudie a:hover {
  background-color: #2a4a0f;
  text-decoration: none;
}
body.general.universe .universeheader ul.universe-icons li.mitstudie a::after {
  content: "";
  font-family: "font-awesome";
  font-weight: 300;
  display: inline-block;
  padding-left: 0.5rem;
}
body.general.universe .universeheader ul.universe-icons li.active {
  background-color: #fff;
}
body.general.universe .universeheader .universe-utility {
  display: none;
  background-color: #fff;
  width: 380px;
  padding: 1rem 1rem 0 1rem;
  z-index: 101;
  right: 0;
  box-shadow: 2px 10px 10px rgba(0, 0, 0, 0.3);
  top: 100%;
  position: absolute;
}
body.general.universe .universeheader .universe-utility.active {
  display: block;
}
body.general.universe .universeheader .universe-utility .map-container {
  margin-bottom: 1rem;
}
body.general.universe .universeheader .universe-utility .map-container .vcard hr {
  display: none;
}
body.general.universe .universeheader .universe-utility .map-container li.react-autosuggest__suggestion {
  margin-bottom: 0;
}
body.general.universe .universeheader .universe-utility .universe-utility-links div.au_collapsible {
  background-color: #379fcb;
  color: #fff;
  padding: 0;
}
body.general.universe .universeheader .universe-utility .universe-utility-links div.au_collapsible .csc-header {
  margin-bottom: 0;
  padding: 1rem;
}
body.general.universe .universeheader .universe-utility .universe-utility-links div.au_collapsible .csc-header:after {
  color: #fff;
  font-size: 27px;
  margin-top: 0;
}
body.general.universe .universeheader .universe-utility .universe-utility-links div.au_collapsible .csc-header + div {
  background-color: #003e5c;
  overflow: auto;
  padding: 1rem;
}
body.general.universe .universeheader .universe-utility .universe-utility-links div.au_collapsible .csc-header + div ul {
  display: flex;
  justify-content: space-between;
  margin-left: 0;
}
body.general.universe .universeheader .universe-utility .universe-utility-links div.au_collapsible .csc-header + div ul li {
  margin-bottom: 0;
  width: 50%;
}
body.general.universe .universeheader .universe-utility .universe-utility-links div.au_collapsible .csc-header + div ul li a {
  margin-bottom: 0;
  padding: 0.5rem 1rem;
}
body.general.universe .universeheader .universe-utility .universe-utility-links div.au_collapsible .csc-header + div ul li:first-child {
  margin-right: 1rem;
}
body.general.universe .universeheader .universe-utility .universe-utility-links div.au_collapsible h3 {
  margin: 0;
  color: #fff;
  font-size: 1.25rem;
}
body.general.universe .universeheader .universe-utility .universe-utility-links ul {
  margin-bottom: 0;
}
body.general.universe .universeheader .universe-utility .universe-utility-links li a {
  font-family: "AUPassataRegular";
  font-size: 1.25rem;
  padding: 1rem;
  display: block;
  background-color: #379fcb;
  color: #fff !important;
  margin-bottom: 1rem;
  text-transform: uppercase;
  border: none !important;
}
body.general.universe .universeheader .universe-utility .universe-utility-links li a strong {
  font-family: "AUPassataBold";
}
body.general.universe .universeheader .universe-utility .universe-utility-links li.student a {
  background-color: #8bad3f;
}
body.general.universe .universeheader .universe-utility .universe-utility-links li.omnibus a {
  background-color: #666666;
}
body.general.universe .universeheader .universe-utility .universe-utility-links li.phd a {
  background-color: #655a9f;
}
body.general.universe .universeheader .universe-utility .universe-utility-links li.staffservice a {
  background-color: #003e5c;
}
body.general.universe .header-menu {
  padding-left: 0;
  padding-right: 0;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}
body.general.universe .header-menu .mobile-nav {
  position: static;
  padding: 0;
}
body.general.universe .header-menu .mobile-nav button::after {
  position: static;
}
body.general.universe .header-menu + .columns {
  display: none;
}
body.general.universe .header-menu.open + .columns {
  display: block;
}
body.general.universe .header-menu.open + .columns ul li {
  font-size: 1.5rem;
}
body.general.universe > .row,
body.general.universe #au_content {
  background: #fff;
}
body.general.universe > .row:not(.breadcrumb) {
  padding-left: 1.3636364rem;
  padding-right: 1.3636364rem;
}
@media screen and (max-width: 39.9375em) {
  body.general.universe > .row:not(.breadcrumb) {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
body.general.universe .au_alphabox ul.quicklinks li a {
  text-decoration: none;
  color: #fff;
}
body.general.universe .au_flowbox button {
  font-family: inherit;
  color: #003e5c;
}
body.general.universe .au_flowbox button.button {
  color: #fff;
}
body.general.universe .main .find-container {
  border: 2px solid #ccc;
  padding: calc(1rem - 2px) 1rem 1rem 1rem;
  min-height: 361px;
  margin-bottom: 1.3636364rem;
}
body.general.universe .main .find-container h3 {
  /* text-transform: uppercase; */
  font-size: 1.8181812rem;
  margin-bottom: 1rem;
}
body.general.universe .main .front .au_alphabox {
  min-height: 361px;
  margin-bottom: 1.3636364rem;
}
body.general.universe .main .au_alphabox h2 {
  /* text-transform: uppercase; */
  font-size: 1.8181812rem;
}
body.general.universe .main .au_alphabox h2 a {
  text-decoration: none;
}
body.general.universe .main .au_databox.front .content {
  max-height: 200px;
  overflow: auto;
}
body.general.universe .main .au_databox.front.collapse .db-button {
  color: #379fcb;
}
body.general.universe .main .au_databox.front.collapse .db-button > span {
  border-top-width: 4px;
  border-top-color: #379fcb;
  padding-top: 14px;
  text-transform: uppercase;
}
body.general.universe .main .au_databox.front.collapse .db-button::after {
  top: 12px;
}
body.general.universe .main .au_databox.front.collapse .db-button::after {
  color: #379fcb;
}
body.general.universe .main .au_databox_wrapper {
  background-color: #379fcb;
  padding: 1rem;
  min-height: 361px;
  margin-bottom: 1.3636364rem;
}
body.general.universe .main .au_databox_wrapper h2 {
  color: #fff;
  /* text-transform: uppercase; */
  margin-top: 0;
  font-size: 1.8181812rem;
}
body.general.universe .main .au_databox_wrapper p {
  margin: 1rem 0;
  color: #fff;
}
@media screen and (max-width: 39.9375em) {
  body.general.universe .main .find-container,
body.general.universe .main .au_alphabox.front,
body.general.universe .main .au_databox_wrapper {
    min-height: initial;
  }
}
body.general.universe .row.sectionheader {
  position: relative;
}
body.general.universe .row.sectionheader .columns.sectiontitle, body.general.universe .row.sectionheader .columns.languageselector, body.general.universe .row.sectionheader .columns.search {
  padding: 1.5rem 1.3636364rem 1.5rem 0;
}
body.general.universe .row.sectionheader .columns.sectiontitle > h2 {
  font-size: 2.1818181818rem;
  text-transform: uppercase;
  margin: 0 0 0 1.3636364rem;
  font-family: "AUPassataRegular";
}
body.general.universe .row.sectionheader .columns.search input[type=search] {
  margin-bottom: 0;
  height: 100%;
}
body.general.universe .row.sectionheader .columns.languageselector > p {
  float: right;
  margin-bottom: 0;
  padding-top: 0.5rem;
}
@media screen and (max-width: 39.9375em) {
  body.general.universe .row.sectionheader .columns.languageselector {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    position: static;
  }
  body.general.universe .row.sectionheader .columns.languageselector p {
    float: none;
    padding-top: 0;
  }
  body.general.universe .row.sectionheader .columns.search {
    position: static;
    padding: 0 1rem 1rem 1rem;
    display: initial !important;
  }
}
body.general.universe footer .global {
  margin-top: 0;
}
body.general.universe footer .muted {
  margin-top: 20rem;
}
@media screen and (max-width: 39.9375em) {
  body.general.universe footer .muted {
    margin-top: 4rem;
  }
}
body.general.universe .au_alphabox .react-autosuggest__suggestions-list li {
  margin-bottom: 0 !important;
}

.help-modal {
  position: fixed;
  z-index: 102;
  left: 50%;
  top: 30%;
  transform: translate(-50%, -30%);
  width: 100%;
  max-width: 1024px;
  background-color: #fff;
  padding: 1.3636364rem;
}
.help-modal h2 {
  margin-top: 0;
  margin-bottom: 1.3636364rem;
}
.help-modal p {
  margin-bottom: 1.3636364rem;
}
.help-modal button {
  margin-bottom: 0;
}

.help-modal-overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 101;
}