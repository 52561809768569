@import '../settings';
$padding-default: 1.3636364rem;
$staff-gray: #f0efef;

@font-face {
  font-family: 'font-awesome';
  src: url('https://fonts.au.dk/fonts/fa-pro-light-300.otf');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'font-awesome';
  src: url('https://fonts.au.dk/fonts/fa-pro-solid-900.otf');
  font-weight: 900;
  font-style: bold;
}

body.general.universe {
  &.staff {
    background-color: $staff-color;

    a {
      color: $staff-secondary-color;
    }

    .row.sectionheader {
      background-color: $staff-gray;
    }

    .universefooter,
    .localfooter,
    .globalfooter,
    #au_footer {
      background-color: $staff-gray;
    }

    footer.global .columns {
      background-color: $staff-gray;
      color: inherit;

      .seal {
        filter: invert(1);
      }
    }

    footer>.page-id.row {
      color: $staff-gray;

      &:hover {
        color: $staff-secondary-color;
      }
    }

    label,
    li:before {
      color: $staff-color;
    }

    #au_content .column .au_collapsible .csc-header h2,
    #au_content .columns .au_collapsible .csc-header h2,
    .au_collapsible .csc-header h2 {
      color: $staff-secondary-color;
    }

    &.staffservice {
      .row.sectionheader {
        background-color: $staff-secondary-color;

        a {
          color: #fff;
        }

        .sectiontitle>h2 {
          color: #fff;
        }
      }

      .universefooter,
      .localfooter,
      .globalfooter,
      #au_footer {
        background-color: $staff-secondary-color;
      }

      footer {

        .column,
        .columns {
          color: #fff;

          li::before,
          a {
            color: #fff;
          }
        }
      }

      footer.global .columns {
        background-color: $staff-secondary-color;

        .seal {
          filter: none;
        }
      }

      footer>.page-id.row {
        color: $staff-secondary-color;

        &:hover {
          color: #fff;
        }
      }
    }

    .universeheader ul.universe-icons>li.active button {
      color: $staff-color;
    }

    .left-menu a:hover,
    .left-menu li.au_current:before,
    .left-menu li.au_current a,
    .left-menu li.au_current ul a {
      color: $staff-secondary-color !important;
    }

    .au_collapsible {

      & .csc-header h1,
      #au_content .column & .csc-header h1 {
        color: $staff-color;
      }

      & .csc-header h2,
      #au_content .column & .csc-header h2 {
        color: $staff-color;
      }
    }

    .csc-frame.au_collapsible>.csc-header:after,
    .csc-frame.au_collapsed>.csc-header:after {
      color: $staff-color;
    }

    .au_news-list-container button {
      background-color: $staff-color;
    }

    .header-menu {
      .mobile-nav {
        button {
          &::after {
            background-color: $staff-secondary-color;
            box-shadow: 0 7px 0 $staff-secondary-color, 0 14px 0 $staff-secondary-color;
          }
        }
      }

      &.open {
        +.columns {
          a {
            color: $staff-secondary-color;
          }
        }
      }
    }

    #au_alphabox_1:not(.front) {
      .quicklinks, h3 {
        display: none;
      }
    }
  }

  &.staff.staffservice,
  &.student {
    .header-menu {
      .mobile-nav {
        button {
          &::after {
            background-color: #fff;
            box-shadow: 0 7px 0 #fff, 0 14px 0 #fff;
          }
        }
      }

      &.open {
        +.columns {
          a {
            color: #fff;
          }
        }
      }
    }
  }

  &.student {
    background-color: $student-color;

    a {
      color: $student-secondary-color;
    }

    .row.sectionheader {
      background-color: $student-secondary-color;

      a {
        color: #fff;
      }
    }

    .universefooter,
    .localfooter,
    .globalfooter,
    #au_footer {
      background-color: $student-secondary-color;
    }

    footer {

      .column,
      .columns {
        color: #fff;

        li::before,
        a {
          color: #fff;
        }
      }
    }

    footer.global .columns {
      background-color: $student-secondary-color;
    }

    footer>.page-id.row {
      color: $student-secondary-color;

      &:hover {
        color: #fff;
      }
    }

    label,
    li:before {
      color: $student-color;
    }

    ul.useful-links a {
      color: #fff !important;
    }

    #au_content .column .au_collapsible .csc-header h2,
    #au_content .columns .au_collapsible .csc-header h2,
    .au_collapsible .csc-header h2 {
      color: $student-secondary-color;
    }

    .universeheader ul.universe-icons>li.active button {
      color: $student-color;
    }

    .left-menu a:hover,
    .left-menu li.au_current:before,
    .left-menu li.au_current a,
    .left-menu li.au_current ul a {
      color: $student-secondary-color !important;
    }

    .au_collapsible {

      & .csc-header h1,
      #au_content .column & .csc-header h1 {
        color: $student-color;
      }

      & .csc-header h2,
      #au_content .column & .csc-header h2 {
        color: $student-color;
      }
    }

    .csc-frame.au_collapsible>.csc-header:after,
    .csc-frame.au_collapsed>.csc-header:after {
      color: $student-color;
    }

    .au_news-list-container button {
      background-color: $student-color;
    }
  }

  &.level0 header~.row:not(.breadcrumb):first-of-type {
    padding-top: $padding-default * 2;
  }

  .au_focus h1 a {
    color: #fff;
  }

  .au_searchbox {
    position: relative;

    &.active .au_searchform::before {
      display: none;
    }

    .au_searchform {
      margin-top: 0;

      &::before {
        display: inline-block;
        content: '7';
        position: absolute;
        font-family: "au_icons";
        font-size: 1.5rem;
        top: 5px;
        left: 10px;
        color: #ccc;
      }

      input[type="search"] {
        background-color: #fff !important;
        border: 1px solid #ccc !important;
      }
    }

    @include breakpoint(small only) {
      input[type="search"] {
        border-bottom: 1px solid #cacaca;
      }
    }
  }

  .button,
  .au_button a,
  .stafflinks a,
  .aubanner.aubanner-2 a,
  .aubanner.aubanner-2 h2,
  .aubanner.aubanner-2 h3,
  .aubanner.aubanner-2 p,
  .neutral-container div[class*=bg-dark],
  .neutral-container div[class*=bg-dark] a,
  .neutral-container div[class*=bg-dark] li:before,
  span.au_news-single-language a,
  span.au_news-single-print a {
    color: #fff !important;
  }

  .aubanner.aubanner-2.lightgray a,
  .aubanner.aubanner-2.lightgray h2,
  .aubanner.aubanner-2.lightgray h3,
  .aubanner.aubanner-2.lightgray li:before,
  .aubanner.aubanner-2.lightgray p,
  .aubanner.aubanner-2.white a,
  .aubanner.aubanner-2.white h2,
  .aubanner.aubanner-2.white h3,
  .aubanner.aubanner-2.white li:before,
  .aubanner.aubanner-2.white p,
  .bg-dark-gray.aubanner-2:hover:after,
  .bg-lightgray,
  .neutral-container div[class*=bg-dark] .bg-lightgray {
    color: #333 !important;
  }

  $universe-icon-font-size: 3.6rem;

  .universeheader {
    position: relative;

    .columns>div {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-end;
      align-items: center;
      margin: 1rem 0 0 0;

      @include breakpoint(medium up) {
        .avatar {
          order: 100;
          margin-right: 0;
        }

        ul.universe-icons {
          flex-basis: auto;
        }
      }

      @include breakpoint(small only) {
        align-items: flex-start;
      }

      >a,
      .au a,
      .avatar>a,
      .mitstudie>a,
      button:not(.copy-to-clipboard) {
        text-transform: uppercase;
        color: #fff;
      }

      button,
      .avatar>a {
        display: block;
        font-family: 'AUPassataRegular';
        font-size: .9rem;
        text-align: center;
        line-height: 1;
        padding: .5rem 0;
        cursor: pointer;
      }

      @include breakpoint(small only) {
        .avatar {
          display: flex;
          justify-content: flex-end;
          margin: 0;
          flex-basis: calc(100% - 19.7rem);

          .sub-nav>button,
          >a {
            display: flex;
            align-items: center;
            text-transform: none;
            font-size: 1.3rem;
            margin: 0 0.6rem;
            text-align: right;
            padding: 0;

            &::before {
              order: 2;
              margin-left: .5rem;
            }
          }
        }
      }

      button {
        &::before {
          display: block;
          font-family: 'au_icons';
          font-size: $universe-icon-font-size;
          text-transform: none;
          overflow: hidden;
          height: calc(#{$universe-icon-font-size} - 11px);
        }

        &.btn-system {
          &::before {
            content: 'Z';
          }
        }

        &.btn-find {
          &::before {
            content: 'f';
          }
        }

        &.btn-menu {
          &::before {
            content: 'm';
          }
        }
      }
    }

    .universe-home {
      flex-grow: 1;
      font-size: 1.8181818182rem;
      text-transform: uppercase;
      font-family: 'AUPassataRegular';
      margin-bottom: 0;

      >a {
        color: #fff;

        >strong {
          font-family: 'AUPassataBold';
        }
      }
    }

    .avatar {
      margin: 0 .5rem;
      z-index: 102;
      position: relative;

      .btn-help {
        position: absolute;
        top: -3px;
        right: -10px;
        font-family: "font-awesome";
        font-weight: bold;
        font-size: 2rem;
        color: #fabb00 !important;
        padding: 0;

        @include breakpoint(small only) {
          top: -4px;
          right: -4px;
          font-size: 1.6rem;
        }
      }

      >.sub-nav>button,
      >a {
        &::before {
          font-family: 'font-awesome';
          font-weight: 300;
          display: block;
        }
      }

      >.sub-nav {
        position: relative;

        >button::before {
          content: ''; // Name
          font-size: 2.54rem;
        }

        &.sub-nav--toggled {
          >button::before {
            font-weight: bold;
          }
        }
      }

      >a {
        &:hover {
          text-decoration: none;
        }

        &::before {
          content: ""; // Login
          font-size: 2.6rem;
          text-transform: none;
        }
      }

      .sub-nav__content {
        position: absolute;
        background-color: #fff;
        top: 100%;
        right: 0;
        padding: 2rem;
        width: 270px;
        box-shadow: 0 0.2rem 0.6rem 0 rgba(0, 0, 0, 0.4);
        z-index: 101;

        button {
          color: rgb(10, 10, 10);
          font-family: 'AUPassataRegular';
        }

        .sub-nav__user {
          .sub-nav__user-name {
            margin: 0 0 .4rem 0;
          }

          button {
            font-size: 1rem;
            color: #6e6e6e;

            &:hover {
              color: #65a1ba;

              &::after {
                font-family: 'font-awesome';
                font-weight: 300;
                margin-left: .5rem;
                content: '\f0c5';
              }
            }
          }
        }

        .sub-nav__item {
          display: block;
          padding: .3125rem 0;
          margin-bottom: .3476rem;
          color: #000;

          &:hover {
            color: #5e5e5e;
            text-decoration: none;
          }
        }

        .sub-nav__item--icon {
          &::before {
            content: attr(data-icon);
            font-family: "font-awesome";
            font-weight: 300;
            margin-right: .5rem;
          }
        }
      }
    }

    ul.universe-icons {
      display: flex;
      justify-content: flex-end;
      flex-basis: 100%;
      align-items: center;
      position: relative;
      z-index: 100;
      margin-bottom: 0;

      @include breakpoint(small only) {
        margin-top: .5rem;
      }

      li {
        &.au {
          margin-right: 1rem;
        }

        &.mitstudie {
          background-color: #667e2e;
          font-family: "AUPassataBold";
          margin-right: 1.4rem;

          a {
            display: block;
            box-shadow: rgba(0, 0, 0, 0.4) 0px 3.2px 9.6px 0px;
            padding: 6.18px 15.82px;

            &:hover {
              background-color: $student-secondary-color;
              text-decoration: none;
            }

            &::after {
              content: '\f090';
              font-family: "font-awesome";
              font-weight: 300;
              display: inline-block;
              padding-left: .5rem;
            }
          }
        }

        &.active {
          background-color: #fff;
        }
      }
    }

    .universe-utility {
      display: none;
      background-color: #fff;
      width: 380px;
      padding: 1rem 1rem 0 1rem;
      z-index: 101;
      right: 0;
      box-shadow: 2px 10px 10px rgba(0, 0, 0, 0.3);
      top: 100%;
      position: absolute;

      &.active {
        display: block;
      }

      .map-container {
        margin-bottom: 1rem;

        .vcard {
          hr {
            display: none;
          }
        }

        li.react-autosuggest__suggestion {
          margin-bottom: 0;
        }
      }

      .universe-utility-links {
        div.au_collapsible {
          background-color: $staff-color;
          color: #fff;
          padding: 0;

          .csc-header {
            margin-bottom: 0;
            padding: 1rem;

            &:after {
              color: #fff;
              font-size: 27px;
              margin-top: 0;
            }

            +div {
              background-color: $staff-secondary-color;
              overflow: auto;
              padding: 1rem;

              ul {
                display: flex;
                justify-content: space-between;
                margin-left: 0;

                li {
                  margin-bottom: 0;
                  width: 50%;

                  a {
                    margin-bottom: 0;
                    padding: .5rem 1rem;
                  }

                  &:first-child {
                    margin-right: 1rem;
                  }
                }
              }
            }
          }

          h3 {
            margin: 0;
            color: #fff;
            font-size: 1.25rem;
          }
        }

        ul {
          margin-bottom: 0;
        }

        li {
          a {
            font-family: "AUPassataRegular";
            font-size: 1.25rem;
            padding: 1rem;
            display: block;
            background-color: $staff-color;
            color: #fff !important;
            margin-bottom: 1rem;
            text-transform: uppercase;
            border: none !important;

            strong {
              font-family: "AUPassataBold";
            }
          }

          &.student {
            a {
              background-color: $student-color;
            }
          }

          &.omnibus {
            a {
              background-color: #666666;
            }
          }

          &.phd {
            a {
              background-color: $phd-color;
            }
          }

          &.staffservice {
            a {
              background-color: $staff-secondary-color;
            }
          }
        }
      }
    }
  }

  .header-menu {
    padding-left: 0;
    padding-right: 0;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;

    .mobile-nav {
      position: static;
      padding: 0;

      button {
        &::after {
          position: static;
        }
      }
    }

    +.columns {
      display: none;
    }

    &.open {
      +.columns {
        display: block;

        ul {
          li {
            font-size: 1.5rem;
          }
        }
      }
    }
  }

  >.row,
  #au_content {
    background: #fff;
  }

  >.row:not(.breadcrumb) {
    padding-left: $padding-default;
    padding-right: $padding-default;

    @include breakpoint(small only) {
      padding-left: 1rem;
      padding-right: 1rem;
    }
  }

  $front-boxes-height: 361px;
  $front-boxes-font-size: 1.8181812rem;

  .au_alphabox {
    ul.quicklinks li a {
      text-decoration: none;
      color: #fff;
    }
  }

  .au_flowbox {
    button {
      font-family: inherit;
      color: $staff-secondary-color;

      &.button {
        color: #fff;
      }
    }
  }

  .main {
    .find-container {
      border: 2px solid #ccc;
      padding: calc(1rem - 2px) 1rem 1rem 1rem;
      min-height: $front-boxes-height;
      margin-bottom: $padding-default;

      h3 {
        /* text-transform: uppercase; */
        font-size: $front-boxes-font-size;
        margin-bottom: 1rem;
      }
    }

    .front .au_alphabox {
      min-height: $front-boxes-height;
      margin-bottom: $padding-default;
    }

    .au_alphabox {
      h2 {
        /* text-transform: uppercase; */
        font-size: $front-boxes-font-size;

        a {
          text-decoration: none;
        }
      }
    }

    .au_databox.front {
      .content {
        max-height: 200px;
        overflow: auto;
      }

      &.collapse .db-button {
        color: $staff-color;

        >span {
          border-top-width: 4px;
          border-top-color: $staff-color;
          padding-top: 14px;
          text-transform: uppercase;
        }

        &::after {
          top: 12px;
        }

        &::after {
          color: $staff-color;
        }
      }
    }

    .au_databox_wrapper {
      background-color: $staff-color;
      padding: 1rem;
      min-height: $front-boxes-height;
      margin-bottom: $padding-default;

      h2 {
        color: #fff;
        /* text-transform: uppercase; */
        margin-top: 0;
        font-size: $front-boxes-font-size;
      }

      p {
        margin: 1rem 0;
        color: #fff;
      }
    }

    @include breakpoint(small only) {

      .find-container,
      .au_alphabox.front,
      .au_databox_wrapper {
        min-height: initial;
      }
    }
  }

  .row.sectionheader {
    position: relative;

    .columns {

      &.sectiontitle,
      &.languageselector,
      &.search {
        padding: 1.5rem $padding-default 1.5rem 0;
      }

      &.sectiontitle {
        >h2 {
          font-size: 2.1818181818rem;
          text-transform: uppercase;
          margin: 0 0 0 $padding-default;
          font-family: "AUPassataRegular";
        }
      }

      &.search input[type="search"] {
        margin-bottom: 0;
        height: 100%;
      }

      &.languageselector>p {
        float: right;
        margin-bottom: 0;
        padding-top: .5rem;
      }

      @include breakpoint(small only) {
        &.languageselector {
          display: flex;
          flex-direction: column;
          justify-content: center;
          text-align: center;
          position: static;

          p {
            float: none;
            padding-top: 0;
          }
        }

        &.search {
          position: static;
          padding: 0 1rem 1rem 1rem;
          display: initial !important;
        }
      }
    }
  }

  footer {
    .global {
      margin-top: 0;
    }

    .muted {
      margin-top: 20rem;

      @include breakpoint(small only) {
        margin-top: 4rem;
      }
    }
  }

  .au_alphabox .react-autosuggest__suggestions-list li {
    margin-bottom: 0 !important;
  }
}

.help-modal {
  position: fixed;
  z-index: 102;
  left: 50%;
  top: 30%;
  transform: translate(-50%, -30%);
  width: 100%;
  max-width: 1024px;
  background-color: #fff;
  padding: 1.3636364rem;

  h2 {
    margin-top: 0;
    margin-bottom: 1.3636364rem;
  }

  p {
    margin-bottom: 1.3636364rem;
  }

  button {
    margin-bottom: 0;
  }
}

.help-modal-overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 101;
}